<template>
    <div class="pb-152">
        <v-row no-gutters justify="center" align="center">
            <v-col cols="12">
                <!-- 额外光晕的元素 -->
                <div class="extra-glow-top-right"></div>
                <div class="extra-glow-bottom-left"></div>
               <v-card color="black" class="px-10 py-10 est-card mx-auto card-outlined" :class="darkTheme == 0 ? '' : 'card-outlined'" rounded="0" elevation="12">
                    <div class="cal-36 marcellus font-weight-bold text-white03 mb-4">KOL Earnings Estimator</div>
                    <div class="cal-16 lexend-deca text-primaryGrey font-weight-bold mb-4">Kingdom Size</div>
                    <v-row no-gutters justify="start">
                        <v-col cols="12" sm="12" md="7" lg="7" xl="7" xxl="7" class="pr-2 r-p-x-0">
                            <v-text-field v-model="nFREstimatorRequest.citizen" type="number" min="0" step="1" density="comfortable" rounded="0" variant="outlined" bg-color="black" reverse hide-details @blur="estimateNfr"></v-text-field>
                            <div class="cal-16 lexend-deca text-primaryGrey mt-2">Citizens</div>
                        </v-col>
                        <v-col cols="12" sm="12" md="5" lg="5" xl="5" xxl="5" class="r-m-t-4">
                            <v-text-field v-model="nFREstimatorRequest.shareRewardRatio" type="number" min="0" step="0.01" density="comfortable" rounded="0" variant="outlined" bg-color="black" reverse hide-details @blur="estimateNfr">
                                <template v-slot:append-inner>%</template>
                            </v-text-field>
                            <div class="cal-16 lexend-deca text-primaryGrey mt-2">My share of each winning Reward (R)</div>
                        </v-col>
                    </v-row>
                    <div class="cal-16 lexend-deca text-primaryGrey font-weight-bold mb-4 mt-8">Average Transaction Details</div>
                    <v-row no-gutters justify="start" align="end">
                        <v-col cols="12" sm="12" md="3" lg="3" xl="3" xxl="3">
                            <v-text-field v-model="nFREstimatorRequest.size" type="number" min="0" step="0.000001" density="comfortable" rounded="0" variant="outlined" reverse hide-details class="montserrat" @blur="estimateNfr"></v-text-field>
                            <div class="cal-16 lexend-deca text-primaryGrey mt-2">Size</div>
                        </v-col>
                        <v-col cols="12" sm="12" md="4" lg="4" xl="4" xxl="4" class="px-2 r-p-x-0 r-m-t-4">
                            <v-select v-model="nFREstimatorRequest.symbol" :items="unCryptos" item-title="symbol" item-value="symbol" density="comfortable" rounded="0" variant="outlined" hide-details class="montserrat">
                                <template v-slot:item="{ props, item }">
                                    <v-list-item v-bind="props">
                                        <template v-slot:title>
                                            <span class="mr-2">{{ item.raw.symbol }}</span>
                                        </template>
                                    </v-list-item>
                                </template>
                            </v-select>
                            <div class="hero-16 lexend-deca text-primaryGrey mt-2">Asset</div>
                        </v-col>
                        <v-col cols="12" sm="12" md="5" lg="5" xl="5" xxl="5" class="r-m-t-4">
                            <v-select v-model="samePriceRatio" :items="profitExpectancies" item-value="priceChangeRatio" bg-color="light-green" density="comfortable" rounded="0" variant="outlined" reverse hide-details clearable class="montserrat" @update:modelValue="changeSamePriceRatio">
                                <template v-slot:selection="{ item }">
                                    <span>{{ `${item.raw.title} (+${item.raw.priceChangeRatio}%)`}}</span>
                                </template>
                                <template v-slot:item="{ props, item }">
                                    <v-list-item v-bind="props">
                                        <template v-slot:title>
                                            <span>{{ `${props.key + 1} - ${item.raw.title} (+${item.raw.priceChangeRatio}%)`}}</span>
                                        </template>
                                    </v-list-item>
                                </template>
                            </v-select>
                            <div class="cal-16 lexend-deca text-primaryGrey mt-2">Profit Expectancy (PE)</div>
                        </v-col>
                    </v-row>
                    <v-divider color="bg-primaryGrey"  class="my-16 opacity-100"></v-divider>
                    <v-row no-gutters justify="center" align="end">
                        <v-col cols="12" sm="12" md="12" xl="12" xxl="12">
                            <div class="marcellus font-weight-bold home-26 text-white03">Total Earnings</div>
                            <div class="home-64 mt-5 montserrat text-soft-lime-green">
                                <span>{{ nFREstimatorResponse.totalReward == null ? 0 : $tools.formatNumber(nFREstimatorResponse.totalReward, 2) }}</span>
                                <span class="lexend-deca home-24 text-primaryGrey ml-5">{{ nFREstimatorResponse.paymentToken }}</span>
                            </div>
                        </v-col>
                    </v-row>
               </v-card>
            </v-col>
        </v-row>
        <v-row no-gutters justify="start" align="center">
            <v-col cols="12" sm="12" class="mt-100">
                <v-card color="transparent" class="b-card mx-auto" rounded="0" elevation="0">
                    <div class="home-16 lexend-deca text-primaryGrey">Discover Your Potential Earnings in the Untrading Ecosystem</div>
                    <div class="home-26 marcellus font-weight-bold text-white03 my-2">Average Transaction Breakdown</div>
                    <v-expansion-panels>
                        <v-expansion-panel elevation="0" rounded="0">
                            <v-expansion-panel-title collapse-icon="mdi:mdi-minus" class="text-primaryGrey r-p-x-0 px-0 fs-16 lexend-deca" expand-icon="mdi:mdi-plus">
                               Adjust the values to see how different scenarios affect your potential earnings in the Untrading ecosystem!
                            </v-expansion-panel-title>
                            <v-expansion-panel-text class="home-18 text-primary-grey lexend-deca">
                                <div class="home-16 lexend-deca text-primaryGrey">
                                    <div>This tool helps you estimate your earnings based on asset transactions and community engagement. Here's how it works:</div>
                                    <div class="kol-18 font-weight-bold mt-5 text-white03">1. Understand Untrading Assets and Flows:</div>
                                    <ul class="mt-2 ml-5">
                                        <li>Each asset creates a community called a Flow</li>
                                        <li>Flows include current and previous asset owners</li>
                                        <li>Your earnings come from your share in these Flows</li>
                                    </ul>
                                    <div class="kol-18 font-weight-bold mt-5 text-white03">2. Input Transaction Details:</div>
                                    <ul class="mt-2 ml-5">
                                        <li>Option A: Use the uniform price change ratio for a quick estimate</li>
                                        <li>Option B: Manually enter each subsequent sale price for more precise calculations</li>
                                        <li>The tool calculates your potential earnings in each Flow</li>
                                    </ul>
                                </div>
                            </v-expansion-panel-text>
                        </v-expansion-panel>
                    </v-expansion-panels>
           
        <!-- <div class="home-16 lexend-deca mt-8 text-primaryGrey d-flex align-center">
            <div>Uniform price change ratio</div>
            <div class="ml-2">
                <v-text-field v-model="samePriceRatio" type="number" min="0" step="0.01" density="comfortable" rounded="0" variant="outlined" bg-color="grey-darken-4" reverse hide-details @blur="changeSamePriceRatio">
                    <template v-slot:append-inner>%</template>
                </v-text-field>
            </div>
        </div> -->
        <v-row no-gutters justify="center" align="center" class="mt-8 home-16 montserrat">
                <div class="pb-8">
                    <div class="c-est-fr" v-for="(item, index) in owners" :key="`owner-${index}`">
                        <!-- <div class="lexend-deca text-primaryGrey r-text-right">{{ item.ownerNo }}</div> -->
                        <div class="lexend-deca text-primaryGrey r-text-right">
                            <span>{{ $tools.toOrdinalSuffix(item.ownerNo) }} Owner Selling Price</span>
                        </div>
                        <div>
                            <span class="cursor-pointer text-primaryGrey" @click="removeOwner(index)" v-if="item.ownerNo > 1">
                                <v-icon size="20">mdi mdi-minus-circle-outline</v-icon>
                            </span>
                            <span class="cursor-pointer text-primary ml-4 r-m-l-0 r-m-l-2" @click="addOwner()" v-if="item.ownerNo == owners.length">
                                <v-icon size="20">mdi mdi-plus-circle-outline</v-icon>
                            </span>
                        </div>
                        <div class="mb-2">
                            <v-text-field v-model="item.price" type="number" min="0" step="0.01" density="comfortable" rounded="0" variant="outlined" :bg-color="priceBgColor(index)" reverse hide-details @update:focused="changeFocusedPriceIndex($event, index)"></v-text-field>
                            <div>
                                <div v-if="index > 0 && index == focusedPriceIndex" class="h6 montserrat mt-3">
                                    <div class="d-flex justify-space-between mt-1">
                                        <span class="pointer gradient-text-hover" @click="changePrice(0.5, index)">+50%</span>
                                        <span class="pointer gradient-text-hover ml-2" @click="changePrice(0.25, index)">+25%</span>
                                        <span class="pointer gradient-text-hover ml-2" @click="changePrice(0.2, index)">+20%</span>
                                        <span class="pointer gradient-text-hover ml-2" @click="changePrice(0.15, index)">+15%</span>
                                        <span class="pointer gradient-text-hover ml-2" @click="changePrice(0.1, index)">+10%</span>
                                        <span class="pointer gradient-text-hover ml-2" @click="changePrice(0.05, index)">+5%</span>
                                    </div>
                                    <div class="d-flex justify-space-between mt-1">
                                        <span class="pointer gradient-text-hover" @click="changePrice(-0.5, index)">-50%</span>
                                        <span class="pointer gradient-text-hover ml-2" @click="changePrice(-0.25, index)">-25%</span>
                                        <span class="pointer gradient-text-hover ml-2" @click="changePrice(-0.2, index)">-20%</span>
                                        <span class="pointer gradient-text-hover ml-2" @click="changePrice(-0.15, index)">-15%</span>
                                        <span class="pointer gradient-text-hover ml-2" @click="changePrice(-0.1, index)">-10%</span>
                                        <span class="pointer gradient-text-hover ml-2" @click="changePrice(-0.05, index)">-5%</span>
                                    </div>
                                    <div class="d-flex justify-space-between mt-1">
                                        <span class="pointer gradient-text-hover" @click="changePrice(0, index)">OK</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-2">
                            <div>
                                <v-text-field :model-value="$tools.formatNumber(item.reward, 2)" v-if="item.ownerNo > 1" min="0" density="comfortable" variant="underlined" reverse readonly hide-details></v-text-field>
                            </div>
                            <div class="mt-16 mb-5" v-if="owners.length > 1 && index == owners.length - 1">
                                <v-text-field class="custom-text-field" :model-value="nFREstimatorResponse.subtotalReward" density="comfortable" rounded="0" variant="underlined" reverse readonly hide-details width="125" max-width="125"></v-text-field>
                            </div>
                        </div>
                    </div>
                    <div class="text-right float-right" v-if="owners.length > 1">
                        <span class="text-primaryGrey home-16 lexend-deca">Subtotal from one asset transactions</span>
                    </div>
                </div>
        </v-row>
        </v-card>     
    </v-col>
    </v-row>
          
        <v-row no-gutters justify="start" align="center" class="mt-16 r-p-x-8">
            <v-col cols="12" sm="12" md="12">
                <v-card color="transparent" class="com-card mx-auto" rounded="0" elevation="0">
                    <div class="home-20 montserrat font-weight-bold mb-8 text-white03">
                        Community Engagement Factors
                    </div>
                    <v-row no-gutters justify="start" align="start">
                        <v-col cols="12" sm="12" md="6" lg="6" xxl="6" class="pr-2 r-p-x-0 r-m-t-4"> 
                            <v-text-field v-model="nFREstimatorRequest.citizenEngagementRatio" type="number" min="0" max="100" step="0.01" density="comfortable" rounded="0" variant="outlined" bg-color="black" reverse hide-details @blur="estimateNfr">
                                <template v-slot:append-inner>%</template>
                            </v-text-field>
                            <span class="cal-16 lexend-deca text-primaryGrey">Citizen engagement ratio</span>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" lg="6" xxl="6" class="pl-2 r-p-x-0 r-m-t-4"> 
                            <v-text-field v-model="nFREstimatorRequest.citizenOriginatorRatio" type="number" min="0" max="100" step="0.01" density="comfortable"  rounded="0" variant="outlined" bg-color="black" reverse hide-details @blur="estimateNfr">
                                <template v-slot:append-inner>%</template>
                            </v-text-field>
                            <span class="cal-16 lexend-deca text-primaryGrey">Percentage of active citizens starting new Flows</span>
                        </v-col>
                    </v-row>
                    <v-row no-gutters justify="start" align="center" class="mt-5">
                        <v-col cols="12" sm="12" md="12" lg="12" xxl="12" class="r-m-t-4">
                            <v-text-field :model-value="$tools.formatNumber(nFREstimatorResponse.concurrentActiveFlow, 0)" min="0" density="comfortable" rounded="0" variant="outlined" reverse readonly hide-details></v-text-field>
                            <span class="cal-16 lexend-deca text-primaryGrey">Resulting concurrent active Flows</span>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <v-row no-gutters justify="center" align="center" class="mt-100">
            <v-col cols="12" sm="6" md="6" lg="6" xl="6" xxl="6">
                <v-card color="black" class="pa-10 r-card mx-auto card-outlined" rounded="0" elevation="12">
                    <div class="home-26 marcellus font-weight-bold mb-8 text-white03">
                        See Your Results:
                    </div>
                    <div class="lexend-deca my-8 text-primaryGrey cal-16">
                        The calculator shows your total potential earnings across all Flows
                    </div>
                    <v-row no-gutters justify="start" align="center" class="mt-8">
                        <v-col cols="12" sm="12" md="12" xl="12" xxl="23" class="pr-4">
                            <span class="home-56 text-soft-lime-green pr-4">{{ nFREstimatorResponse.totalReward == null ? 0 : $tools.formatNumber(nFREstimatorResponse.totalReward, 2) }}</span>
                            <span class="home-24">{{ nFREstimatorResponse.paymentToken }}</span>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import CryptoAPI from '@/api/crypto-nfr.js';
import EstimatorRequestExamples from '../data/estimator-request-examples.json';
export default {
    data() {
        return {
            // NFR估算请求参数
            nFREstimatorRequest: {
                citizen: null,
                citizenEngagementRatio: null,
                citizenOriginatorRatio: null,
                originatorRewardRatio: null,
                prices: [],
                shareRewardRatio: null,
                size: null,
                symbol: 'unBTC/USDC',
                totalRewardRatio: null
            },
            // 相同的价格比例
            samePriceRatio: null,
            // 聚焦的价格索引
            focusedPriceIndex: -1,
            // NFR估算响应参数
            nFREstimatorResponse: {
                concurrentActiveFlow: 0,
            },
            // 拥有者集合
            owners: [
                { ownerNo: 1, price: null, profit: null, reward: null }
            ],
            // 加密货币列表
            unCryptos: [
                { currency: 'unBTC', symbol: 'unBTC/USDC', name: 'untrading Wrapped Bitcoin' },
                { currency: 'unETH', symbol: 'unETH/USDC', name: 'untrading Wrapped Ether' },
                { currency: 'unLINK', symbol: 'unLINK/USDC', name: 'untrading Wrapped Chainlink' },
                { currency: 'unSOL', symbol: 'unSOL/USDC', name: 'untrading Wrapped Solana' },
                { currency: 'UN²', symbol: 'UN²/USDC', name: 'untrading Wrapped UN' },
            ],
            // 利润预期列表
            profitExpectancies: [
                { category: 'Small', title: 'Hummingbird', feature: 'constant, small gains', priceChangeRatio: 5 },
                { category: 'Small', title: 'Shrew', feature: 'frequent, small meals', priceChangeRatio: 8 },
                { category: 'Small', title: 'Squirrel', feature: 'regular foraging, storing for later', priceChangeRatio: 10 },
                { category: 'Medium', title: 'Rabbit', feature: 'steady grazing, moderate gains', priceChangeRatio: 12 },
                { category: 'Medium', title: 'Deer', feature: 'browsing, larger meals but more time between', priceChangeRatio: 15 },
                { category: 'Medium', title: 'Fox', feature: 'opportunistic, varied diet', priceChangeRatio: 18 },
                { category: 'Large', title: 'Wolf', feature: 'larger, less frequent meals, pack hunting', priceChangeRatio: 20 },
                { category: 'Large', title: 'Lion', feature: 'big kills, longer between meals', priceChangeRatio: 25 },
                { category: 'Large', title: 'Elephant', feature: 'large, steady consumption, long-term strategy', priceChangeRatio: 30 },
                { category: 'Jumbo', title: 'Crocodile', feature: 'large, infrequent meals, patient hunter', priceChangeRatio: 40 },
                { category: 'Jumbo', title: 'Python', feature: 'very large, very infrequent meals', priceChangeRatio: 50 },
                { category: 'Jumbo', title: 'Whale', feature: 'filter feeding, constant but for huge amounts', priceChangeRatio: 68 }
            ],
            // 选中的加密货币
            selectedUnCrypto: null,
            // 加载中
            estimateloading: false,
            // 格式化选项
            maskaOption: {
                mask: '#,###.##'
            }
        }
    },
    components: {},
    created() {

    },
    mounted() {

    },
    computed: {
        
    },
    watch: {
        'nFREstimatorRequest.symbol': {
            handler(n, o) {
                let examples = EstimatorRequestExamples.filter(item => item.symbol == n);
                if (examples.length > 0) {
                    let request = JSON.parse(JSON.stringify(examples[0]));
                    this.nFREstimatorRequest = request;
                    this.owners = request.owners;
                    // 根据参数估算 NFR
                    this.estimateNfr();
                }
            },
            immediate: true
        }
    },
    methods: {
        // 改变相同价格比例
        changeSamePriceRatio() {
            // 第一个价格
            let basePrice = this.owners[0].price;
            // 价格小数位数
            let priceDecimals = EstimatorRequestExamples.filter(item => item.symbol == this.nFREstimatorRequest.symbol)[0].priceDecimals;
            // 循环设置价格
            for (let index = 1; index < this.owners.length; index++) {
                let price = Number((Number(basePrice) * (1 + (this.samePriceRatio / 100))).toFixed(priceDecimals));
                this.owners[index].price = price;
                basePrice = price;
            }
            // 根据参数估算 NFR
            this.estimateNfr();
        },
        // 改变价格聚焦索引
        changeFocusedPriceIndex(focused, index) {
            this.focusedPriceIndex = focused ? index : this.focusedPriceIndex;
        },
        // 价格背景色
        priceBgColor(index) {
            if (index == 0 || this.owners[index].price == null || this.owners[index].price == '' || Number(this.owners[index].price) ==  Number(this.owners[index - 1].price)) {
                return 'dark-grey';
            }
            return Number(this.owners[index].price) >  Number(this.owners[index - 1].price) ? 'dark-green' : 'dark-red';
        },
        // 添加
        addOwner() {
            // 最后一个价格
            let lastPrice = this.owners[this.owners.length - 1].price;
            // 价格小数位数
            let priceDecimals = EstimatorRequestExamples.filter(item => item.symbol == this.nFREstimatorRequest.symbol)[0].priceDecimals;
            let price = Number((Number(lastPrice) * 1.25).toFixed(priceDecimals));
            let owner = {
                ownerNo: this.owners.length + 1,
                price: price,
                profit: null,
                reward: null
            };
            this.owners.push(owner);
            // 根据参数估算 NFR
            this.estimateNfr();
        },
        // 移除
        removeOwner(index) {
            // 移除一个
            this.owners.splice(index, 1);
            // 重置序号
            this.owners.forEach((item, index) => {
                item.ownerNo = index + 1;
            });
            // 根据参数估算 NFR
            this.estimateNfr();
        },
        // 改变价格
        changePrice(ratio, index) {
            if (ratio != 0 && index > 0) {
                let basePrice = this.owners[index - 1].price;
                // 价格小数位数
                let priceDecimals = EstimatorRequestExamples.filter(item => item.symbol == this.nFREstimatorRequest.symbol)[0].priceDecimals;
                let price = Number((Number(basePrice) * (1 + ratio)).toFixed(priceDecimals));
                this.owners[index].price = price;
            }
            // 关闭价格快捷键
            this.focusedPriceIndex = -1;
            // 根据参数估算 NFR
            this.estimateNfr();
        },
        // 检查参数
        async checkParams() {
            // 检查公民
            if (Number(this.nFREstimatorRequest.citizen) <= 0) {
                this.$store.dispatch('snackbarMessageHandler', "Invalid citizen");
                return false;
            }
            // 检查数量
            if (Number(this.nFREstimatorRequest.size) <= 0) {
                this.$store.dispatch('snackbarMessageHandler', "Invalid size");
                return false;
            }
            // 检查共享比例
            if (Number(this.nFREstimatorRequest.shareRewardRatio) <= 0 || Number(this.nFREstimatorRequest.shareRewardRatio) > 100) {
                this.$store.dispatch('snackbarMessageHandler', "Invalid share ratio");
                return false;
            }
            // 检查价格
            if (this.owners.some(item => Number(item.price) < 0)) {
                this.$store.dispatch('snackbarMessageHandler', "Invalid price");
                return false;
            }
            // 检查假定公民参与率
            if (Number(this.nFREstimatorRequest.citizenEngagementRatio) <= 0 || Number(this.nFREstimatorRequest.citizenEngagementRatio) > 100) {
                this.$store.dispatch('snackbarMessageHandler', "Invalid citizen engagement ratio");
                return false;
            }
            // 检查假定公民参与率
            if (Number(this.nFREstimatorRequest.citizenOriginatorRatio) <= 0 || Number(this.nFREstimatorRequest.citizenOriginatorRatio) > 100) {
                this.$store.dispatch('snackbarMessageHandler', "Invalid citizen originator ratio");
                return false;
            }
            return true;
        },
        // 根据参数估算 NFR
        async estimateNfr() {
            // 检查参数
            if (!await this.checkParams()) {
                return;
            }
            // 请求加载中
            this.estimateloading = true;
            // 从拥有者集合提取价格数组
            let prices = this.owners.map(item => item.price);
            this.nFREstimatorRequest.prices = prices;
            // 发送请求
            let res = await CryptoAPI.estimateNfr(this.nFREstimatorRequest);
            let data = res.data;
            if (data.success) {
                this.nFREstimatorResponse = data.data;
                this.owners = data.data.owners;
                this.nFREstimatorResponse.subtotalReward = this.$tools.formatNumber(data.data.subtotalReward, 2);
            }
            // 请求结束
            this.estimateloading = false;
        }
    }
}
</script>

<style scoped>
ul {
    list-style: none;
    /* 去掉默认的列表标记符号 */
    padding-left: 0;
}

li {
    display: flex;
    /* 使用 flexbox 布局 */
    align-items: flex-start;
    /* 对齐文本 */
}

li::before {
    content: '•';
    /* 自定义列表标记符号 */
    font-size: 24px;
    /* 调整符号的大小 */
    margin-right: 10px;
    /* 添加标记符号与文本的间距 */
    line-height: 1;
    /* 控制符号的垂直对齐 */
}
</style>
